import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../../../src/components/Source/firebaseConfig";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function Create() {
  const [user] = useAuthState(auth);
  const [formData, setFormData] = useState({
    title: "",
    content: "", // This will be used for rich text content
    image: "",
    Author: "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleContentChange = (value) => {
    setFormData({ ...formData, content: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handlePublish = () => {
    if (!formData.title || !formData.content || !formData.image) {
      alert("Please fill all the fields");
      return;
    }

    const storageRef = ref(
      storage,
      `/images/${Date.now()}${formData.image.name}`
    );

    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          title: "",
          content: "",
          image: "",
          Author: "",
        });

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "Articles");
          addDoc(articleRef, {
            title: formData.title,
            content: formData.content,
            imageUrl: url,
            createdAt: Timestamp.now().toDate(),
            createdBy: user.displayName,
            userId: user.uid,
            Author: formData.Author,
            likes: [],
            comments: []
          })
            .then(() => {
              toast("Article added successfully", { type: "success" });
              setProgress(0);
            })
            .catch((err) => {
              toast("Error adding article", { type: "error" });
            });
        });
      }
    );
  };

  return (<> <div className="container">
    <div className="p-3 mt-3">
      {!user ? (
        <>
          <h2>
            <Link to="/Login">LOGIN TO CREATE A BLOG</Link>
          </h2>
          Don't have an account? <Link to="/register">Signup</Link>
        </>
      ) : (
        <>
          <h2 className="secoundparm" style={{ textAlign: "center", textTransform: "capitalize" }}>write about AI</h2>
          <div className="form-group">
            <label htmlFor="" style={{ fontWeight: "bold" }}>Author</label>
            <input
              type="text"
              name="Author"
              className="form-control"
              value={formData.Author}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="" style={{ fontWeight: "bold" }}>Add Your blog Title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <label htmlFor="" style={{ fontWeight: "bold" }}>Content</label>
          <ReactQuill
            value={formData.content}
            onChange={handleContentChange}
            modules={Create.modules}
            formats={Create.formats}
          />

          <label htmlFor="" style={{ fontWeight: "bold" }}>IMAGE</label>
          <input
            type="file"
            name="image"
            accept="image/*"
            className="form-control"
            onChange={(e) => handleImageChange(e)}
          />

          {progress === 0 ? null : (
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped "
                style={{ width: `${progress}%` }}
              >
                {`uploading image ${progress}%`}
              </div>
            </div>
          )}
          <button
            className="form-control btn-primary mt-2"
            onClick={handlePublish}
          >
            Publish
          </button>
        </>
      )}
    </div>
    <hr />

  </div>
    <Footer />
  </>

  );
}

Create.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['bold', 'italic', 'underline'],
    ['link'],
    [{ align: [] }],
    ['clean']
  ],
};

Create.formats = [
  'header',
  'font',
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'link',
  'align',
];
