import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { auth, db } from "../Source/firebaseConfig";
import Comment from '../LikeDeleteCooment/Comment';
import './Article.css';

export default function Article() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);  // State for image loading
  const [user] = useAuthState(auth);

  useEffect(() => {
    const docRef = doc(db, "Articles", id);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      setArticle({ ...snapshot.data(), id: snapshot.id });
    });

    return () => unsubscribe();
  }, [id]);

  // Function to handle image load
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="container">
      {article && (
        <div className="blogItem">
          <div className="blogposttitle">
            <h2>{article.title}</h2>
          </div>

          <div className="authordatea">
            <h5><span>Author: </span>{article.createdBy}</h5>
            <div className="date">
              <p>{article.createdAt.toDate().toDateString()}</p>
            </div>
          </div>

          <div className="blogpostimg">
            <img
              src={article.imageUrl}
              alt={article.title}
              className={`article-image ${imageLoaded ? 'image-loaded' : ''}`}
              onLoad={handleImageLoad} // Triggers when the image is fully loaded
            />
          </div>

          <div className="autherdateallcontiner ">
            {/* Main content only */}
            <div dangerouslySetInnerHTML={{ __html: article.content }} />
          </div>

          <div className="Blogpostcomment">
            <Comment id={article.id} />
          </div>
        </div>
      )}
    </div>
  );
}
