import React, { useState, useEffect } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { auth, db } from "../../Source/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import "./LatestBlogs.css"; // Import your CSS file here
import Loder from "../../Loder/Loder"
function LatestBlogs() {
    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [user] = useAuthState(auth);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const articleRef = collection(db, "Articles");
        const q = query(articleRef, orderBy("createdAt", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const articles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(articles);
            setFilteredArticles(articles); // Initially set filtered articles to all articles
            setLoading(false); // Data is loaded, set loading to false
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, []);

    useEffect(() => {
        // Filter articles based on searchQuery
        const filtered = articles.filter(article =>
            article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (article.content && article.content.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (article.createdAt && article.createdAt.toDate().toDateString().toLowerCase().includes(searchQuery.toLowerCase()))
        );
        setFilteredArticles(filtered);
    }, [articles, searchQuery]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleImageLoad = (e) => {
        e.target.classList.add("loaded"); // Add the loaded class when the image has fully loaded
    };

    // Show the top 5 articles
    const topArticles = filteredArticles.slice(0, 5);

    const truncateContent = (content, wordLimit = 10) => {
        if (!content) return "";

        // Remove leading <p> tags
        content = content.replace(/^\s*<p[^>]*>/i, '');
        content = content.replace(/<\/p>\s*$/, '');

        // Truncate content to the desired word limit
        const words = content.split(/\s+/);
        return words.length <= wordLimit ? content : `${words.slice(0, wordLimit).join(" ")}...`;
    };

    return (
        <div className="blog">
            <div className="container">
                <h3 className="latest-tools">New Articles</h3>
            </div>
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                {loading ? ( // Conditional rendering based on loading state
                    <Loder />
                ) : (
                    <div className="grid-container">
                        {/* Featured Articles */}
                        <div className="featured-articles">
                            {topArticles.slice(0, 2).map(({ id, title, content, imageUrl }) => (
                                <div className="featured-article" key={id}>
                                    <Link
                                        to={`/article/${id}`}
                                        className="post-item"
                                        style={{ textDecoration: "none" }} // Remove underline from links
                                    >
                                        <div className="post-img">
                                            <img
                                                src={imageUrl}
                                                className="img-fluid"
                                                alt={title}
                                                onLoad={handleImageLoad}
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="post-content">
                                            <h3
                                                className="post-title"
                                                style={{ margin: 0, color: "black" }} // Ensure no margin and color
                                            >
                                                {title}
                                            </h3>
                                            <p
                                                className="post-description"
                                                style={{ margin: 0, color: "black" }} // Ensure no margin and color
                                            >
                                                {truncateContent(content, 10)} <span className="read-more">..<br /> Read More</span>
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>

                        {/* Smaller Articles */}
                        <div className="smaller-articles">
                            {topArticles.slice(2, 5).map(({ id, title, content, imageUrl }) => (
                                <div className="small-blog-item" key={id}>
                                    <Link
                                        to={`/article/${id}`}
                                        className="post-item"
                                        style={{ textDecoration: "none" }} // Remove underline from links
                                    >
                                        <div className="post-img">
                                            <img
                                                src={imageUrl}
                                                className="img-fluid"
                                                alt={title}
                                                onLoad={handleImageLoad}
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="post-content">
                                            <h3
                                                className="post-title"
                                                style={{ margin: 0, color: "black" }} // Ensure no margin and color
                                            >
                                                {title}
                                            </h3>
                                            <p
                                                className="post-description"
                                                style={{ margin: 0, color: "black" }} // Ensure no margin and color
                                            >
                                                {truncateContent(content, 10)} <span className="read-more">..<br /> Read More</span>
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default LatestBlogs;
