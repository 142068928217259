import Navbar from "./components/Navbar/Navbar.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Article from "./components/Article/Article";
import Create from "./components/Create/Create.jsx";
import Home from "./components/Home/Home";
import Support from "./components/Support/Support";
import Privacypolicy from "./components/Privacypolicy/Privacypolicy";
import Blogs from "./components/Blogs/Blogs.js";
import Tools from "./components/Tools/Tools.js";
import PostTool from "./components/Tools/PostTool/PostTool";
import Tool from "./components/Tools/Tool/Tool.js"; import transitions from "bootstrap";
import Aboutus from "./components/Home/Aboutus/Aboutus.js";
function App() {
  return (

    <Router>
      <Navbar />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/Login" element={<Login />} /> <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/article/:id" element={<Article />} />
        <Route path="/Create" element={<Create />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/" element={<Home />} />
        <Route path="/Support" element={<Support />} />
        <Route path="/Privacypolicy" element={<Privacypolicy />} />
        <Route path="/Tools" element={<Tools />} />
        <Route path="/PostTool" element={<PostTool />} />
        <Route path="/Tool/:id" element={<Tool />} />
      </Routes>
    </Router>

  );
}
export default App;
