import React, { useState, useEffect, useRef } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { auth, db } from "../Source/firebaseConfig";
import DeleteArticle from "../LikeDeleteCooment/DeleteArticle";
import { useAuthState } from "react-firebase-hooks/auth";
import LikeArticle from "../LikeDeleteCooment/LikeArticle";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import "./Blogs.css"; // Ensure this path is correct or remove if not needed

function Blogs() {
    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [user] = useAuthState(auth);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true); // New state for loading
    const articlesPerPage = 6;

    const currentPageRef = useRef(currentPage);

    useEffect(() => {
        const articleRef = collection(db, "Articles");
        const q = query(articleRef, orderBy("createdAt", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const fetchedArticles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(fetchedArticles);
            setFilteredArticles(fetchedArticles); // Set filtered articles
            setLoading(false); // Data has been fetched
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const filtered = articles.filter(article =>
            article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (article.content && article.content.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (article.createdAt && article.createdAt.toDate().toDateString().toLowerCase().includes(searchQuery.toLowerCase()))
        );
        setFilteredArticles(filtered);
        setCurrentPage(1);
    }, [articles, searchQuery]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleImageLoad = (e) => {
        e.target.classList.add("loaded");
    };

    const indexOfLastArticle = currentPageRef.current * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

    const paginate = (pageNumber, e) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        currentPageRef.current = pageNumber;
    };

    const truncateContent = (content, wordLimit = 20) => {
        if (!content) return "";

        // Remove leading <p> tags
        content = content.replace(/^\s*<p[^>]*>/i, '');
        content = content.replace(/<\/p>\s*$/, '');

        // Truncate content to the desired word limit
        const words = content.split(/\s+/);
        return words.length <= wordLimit ? content : `${words.slice(0, wordLimit).join(" ")}...`;
    };

    return (
        <>
            <div className='blog'>
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="search-container">
                        <input
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                            type="text"
                            value={searchQuery}
                            onChange={handleSearch}
                            placeholder="Search Now For What You Want To Read About....."
                            className="search-input"
                        />
                    </div>

                    {loading ? (
                        <div className="row gy-4 posts-list">
                            {Array.from({ length: articlesPerPage }).map((_, index) => (
                                <div className="col-xl-4 col-md-6" key={index}>
                                    <div className="skeleton-loading">
                                        <div className="skeleton-img"></div>
                                        <div className="skeleton-title"></div>
                                        <div className="skeleton-text"></div>
                                        <div className="skeleton-text"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <div className="row gy-4 posts-list">
                                {filteredArticles.length === 0 && searchQuery !== "" ? (
                                    <div className="no-results-message">
                                        <p>No data found.</p>
                                    </div>
                                ) : (
                                    currentArticles.map(({ id, title, content, imageUrl, createdBy, userId, likes }) => (
                                        <div className="col-xl-4 col-md-6" key={id} id="blogsss">
                                            <div className="post-item position-relative h-100">
                                                <div className="post-img position-relative overflow-hidden">
                                                    <Link to={`/article/${id}`} style={{ textDecoration: "none" }}>
                                                        <img src={imageUrl} className="img-fluid" alt="" onLoad={handleImageLoad} loading="lazy" />
                                                    </Link>
                                                </div>
                                                <div className="likecommentcontiner">
                                                    {user && <LikeArticle id={id} likes={likes} />}
                                                    <div className="LikeArtical d-flex mt-2">
                                                        <span style={{ marginLeft: "15px", textTransform: "uppercase", fontWeight: "bold", fontSize: "15px" }}>
                                                            {likes?.length}
                                                            <span className="fa fa-heart checked" style={{ marginLeft: "5px", color: "red" }}></span>
                                                        </span>
                                                    </div>
                                                    <div className="deleteArtical">
                                                        {user && user.uid === userId && (
                                                            <DeleteArticle id={id} imageUrl={imageUrl} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="post-content d-flex flex-column">
                                                    <Link to={`/article/${id}`} style={{ textDecoration: "none" }}>
                                                        <h3 className="post-title" style={{ color: "black" }}>{title}</h3>
                                                        <p className="post-disc">{truncateContent(content)} </p>
                                                        <span>Read more</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>

                            <ul className="pagination" style={{ marginTop: "35px" }}>
                                {Array.from({ length: Math.ceil(filteredArticles.length / articlesPerPage) }, (_, i) => (
                                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                        <a href="#" className="page-link" onClick={(e) => paginate(i + 1, e)}>
                                            {i + 1}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Blogs;
